import audioBufferToWAV from 'audiobuffer-to-wav';

// We can use same AudioContexts for a process.
const audioContext = new (window.AudioContext || window.webkitAudioContext || function(){})();

/**
 * Chromium should be supporting latest OfflineAudioContext.
 * We just export the music as wav format.
 * For gaining we will use linearRampToValueAtTime same as howler. If we need to use exponential, better change at both places.
 *
 * Full spec on AudioContext stuff https://www.w3.org/TR/webaudio/
 */
class OfflineMusicRenderer {
    /**
     * Will return the wav as blob as a promise.
     */
    process(musicManifest) {
        this.musicManifest = musicManifest;
        return this.getData(this.musicManifest.getSrcForOfflineRender())
            .then((audioData) => this.renderBufferOffline(audioData))
            .then((renderedBuffer) => audioBufferToWAV(renderedBuffer));
    }

    renderBufferOffline(audioData) {
        return new Promise((resolve, reject) => {
            audioContext.decodeAudioData(audioData, (buffer) => {
                //create node graph
                let targetDuration = this.musicManifest.duration / 1000, //sec
                    targetLength = targetDuration * buffer.sampleRate;
                let offlineCtx = new OfflineAudioContext(buffer.numberOfChannels,
                    targetLength,
                    buffer.sampleRate),
                    source = offlineCtx.createBufferSource(),
                    gainNode = offlineCtx.createGain();

                source.buffer = buffer;

                const fadeLengthSeconds = Math.min(7.0, targetDuration);
                const fadeStart = targetDuration - fadeLengthSeconds;

                gainNode.gain.setValueAtTime(1, fadeStart);
                gainNode.gain.linearRampToValueAtTime(0, targetDuration);

                //connect the nodes
                source.connect(gainNode);
                gainNode.connect(offlineCtx.destination);

                source.start(0, 0, targetDuration);
                source.stop(targetDuration);

                offlineCtx.startRendering()
                    .then(function(renderedBuffer) {
                        resolve(renderedBuffer);
                    });
            });
        });
    }

    /*
     * Returns the data from the src url
     */
    getData(src) {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', src, true);
            xhr.responseType = 'arraybuffer';

            xhr.onload = function() {
                let audioData = xhr.response;
                resolve(audioData, xhr);
            };

            xhr.onerror = function() {
                reject(xhr);
            };

            xhr.send();
        });
    }
}

export default OfflineMusicRenderer;
