import './index.styl';
import StoryCapturer from './story-capturer';
import parseQueryString from '../utils/parse-query-string';

/*
 * The job parameters for the capture are retrieved from the server
 * in the form of a manifest JSON file. The location of this file is passed
 * as a 'manifest=' query param such as:
 * http://localhost:3000/?manifest=/dist/public/examples/renderframeparts/framemanifest-3.json
 */
const queryParams = parseQueryString(window.location.search);
var manifestUri = "manifest.json";
if ('manifest' in queryParams) {
    manifestUri = queryParams.manifest;

    // Get origin for manifest by providing window.location.origin as the backup
    // if none is specified.
    const manifestOrigin = new URL(manifestUri, window.location.origin).origin;
    if (window.location.origin !== manifestOrigin) {
        throw "host origin " + window.location.origin + " did not match manifest origin " + manifestOrigin;
    }
}

/**
 * When loading public (app.js), the Story Capturer is initialized and immediately instructed to start capturing
 * the Story content.
 * 1. Download the specified manifest
 * 2. Render either the audio or the video depending on the manifest and upload it
 *    to the capture server.
 * @type {StoryCapturer}
 */
window.storyCapturer = new StoryCapturer(manifestUri);
window.storyCapturer.capture();
