import { MusicManifest, StoryAnimator } from 'photos-story-animator';
import OfflineMusicRenderer from './offline-music-renderer.js';

/**
 * A story capturing tool.
 * Captures either the audio or frames of a story.
 * * Audio is captured by rendering it offline and uploading it to the
 *   specified REST endpoint.
 * * Frames are captured by walking through the story at the specified
 *   frame rate and uploading them through a websocket connection.
 */
export default class StoryCapturer {
    constructor(manifest, options) {
        this.options = options || {};
        this.numTotalFrames = null;
        this.numFramesToRender = null;
        // which frame we're currently capturing
        this.frame = 0;
        // number of rendered frames
        this.numRenderedFrames = 0;
        // the canvas element we're capturing from
        this.canvas = null;
        // websocket connection to post frames to
        this.websocket = null;
        // buffer for messages until websocket connection is ready
        this.bufferedMessages = [];
        // player needs to be seeked once
        this.playerNeedsSeek = true;
        this.start = Date.now();

        // setup manifest used to drive capture
        this.setupManifest(manifest);
        // Now the logs are hooked to out put from the process itself, after Al2 migration this hijack is not needed
        this._hijackConsoleLogs();
    }

    setupManifest(manifest) {        
        // if manifest is a string, that means we got the uri path of the manifest that should be loaded
        // otherwise, if manifest is an object, that means we got the actual manifest object and can skip fetching manifest later.
        if (typeof manifest == 'string') {
            this.manifestUri = manifest;
            this.manifest = null;
        } else {
            this.manifest = manifest;
        }
    }

    capture() {
        return new Promise((resolve, reject) => {
            if (this.manifest == null) {
                this._fetchManifest();
            } else {
                this._initStoryAnimator(this.manifest);
            }
            this.notifyWhenCaptureComplete = () => {
                this.endBufferWrite();
                resolve("capture complete");
            };
        });
    }

    endBufferWrite() {
        if (this.manifest.disableJettyServer && this.options.endBufferWrite) {
            this.options.endBufferWrite();
        }
    }

    loadSampleManifest() {
        this._initStoryAnimator(SAMPLE_MANIFEST);
    }

    _fetchManifest() {
        fetch(this.manifestUri, {
            headers: [
                ['Content-Type', 'application/json']
            ]
        })
            .then(response => {
                if (response.ok) {
                    response.json().then((json) => {
                        this._initStoryAnimator(json);
                    });
                } else {
                    throw 'Error loading manifest response';
                }
            })
            .catch(() => {
                throw 'Error loading manifestUri';
            });
    }

    /**
     * Captures a frame from the canvas
     * Invoked every time a frame is drawn
     * @private
     */
    _captureCanvas() {
        if (this.playerNeedsSeek) {
            // Player needs initial seek to put it in the
            // right place.
            this.playerNeedsSeek = false;
            const nextInstant = this.frame * 1000 / this.manifest.fps;
            this.storyAnimator.renderFrameAtTime(nextInstant);

            return;
        }

        const percentCompleted = Math.floor(this.numRenderedFrames * 100 / this.numFramesToRender);
        if (Math.floor(this.frame % (this.numFramesToRender * 0.1)) === 0) {
            console.log('capture ' + percentCompleted + '% completed', Date.now() - this.start);
        }

        if (this.manifest.disableJettyServer && this.options.bufferWriter) {
            // TODO: Add a debug flag and save as images to disk using toDataUrl for debugging purposes
            const buffer = this.canvas.toBuffer('raw');
            this.options.bufferWriter(buffer);
        } else {
            const ws = this.websocket;
            const msg = {
                captureCommand: 'ADD_FRAME',
                frameNumber: this.frame,
                dataUrl: this.canvas.toDataURL('image/jpeg', 0.80)
            };
            // Send the frame to the server
            this.bufferedMessages.push(msg);
            this._sendBufferedMessages(ws);
        }

        // Advance to the next frame number
        this.frame++;
        this.numRenderedFrames++;

        if (this.numRenderedFrames < this.numFramesToRender) {
            const nextInstant = this.frame * 1000 / this.manifest.fps;
            // The code is going in a recursive mode without setTimeout.
            // TODO larger refactor to implment a proper Observer pattern
            setTimeout(() => {
                this.storyAnimator.renderFrameAtTime(nextInstant);
            }, 0);
        } else {
            // Send a message that says we are all done capturing the frames
            console.log('capture sequence completed', Date.now() - this.start);
            if (!this.manifest.disableJettyServer) {
                const completedMessage = {
                    captureCommand: 'COMPLETE_SUCCESS',
                };
    
                this.bufferedMessages.push(completedMessage);
                this._sendBufferedMessages(ws);
            }
            this.notifyWhenCaptureComplete();
        }
    }

    _sendBufferedMessages(ws) {
        if (ws && ws.readyState === 1) {
            while (this.bufferedMessages.length > 0) {
                // Needs to use shift so messages are pulled
                // off the front of the queue.
                const nextMsg = this.bufferedMessages.shift();
                nextMsg.jobId = this.manifest.jobId;
                ws.send(JSON.stringify(nextMsg));
            }
        }
    }

    /**
     * TO DO: Remove this once chromium is completely deprecated from service 
     * https://issues.labcollab.net/browse/CURATE-1294
     * Capture audio using OfflineMusicRenderer as a Blob and POST to manifest.uploadAudioLink
     * @private
     * @Returns Promise
     */
    _captureAudio() {
        const { storyNarrative } = this.manifest;
        let musicManifest = new MusicManifest(storyNarrative, this.storyAnimator.duration()),
            offlineMusicRenderer = new OfflineMusicRenderer();

        return offlineMusicRenderer.process(musicManifest)
            .then((wav) => {
                let blob = new window.Blob([new DataView(wav)], { type: 'audio/wav' });

                const xhr = new XMLHttpRequest;
                xhr.open('POST', this.manifest.uploadAudioLink, false);
                xhr.send(blob);

                return xhr.status;
            });
    }

    /**
     * Creates the storyAnimator object which then triggers captureContent on player ready
     */
    _initStoryAnimator(manifest) {
        console.log('loading content and initializing story animator');
        const element = !this.options.isBackendRender ? document.querySelector('#storyAnimator') : undefined;
        const { createCanvas, imageLoadOperation, videoLoadOperation } = this.options;
        this.storyAnimator = new StoryAnimator(element, { 
            createCanvas, 
            imageLoadOperation,
            videoLoadOperation,
            webViewBridge: false,
            musicDisabled: true,
            stylingVersion: manifest.stylingVersion,
            disableFadeOut: manifest.disableFadeOut,
            isFullscreen: manifest.isFullscreen,
        });
        this.manifest = manifest;
        if (manifest.websocketServer) {
            this.websocket = new window.WebSocket(manifest.websocketServer);
        }

        this.storyAnimator.on('playerLoadComplete', this._onPlayerLoadComplete.bind(this));
        this.storyAnimator.on('loadError', this._onLoadError.bind(this));

        const { storyNarrative, width, height, imageUrls, videoUrls } = manifest;
        console.log("number of images to load :", imageUrls.length);
        const data = { storyNarrative, width, height, imageUrls, videoUrls };
        this.storyAnimator.start(data);
    }

    _onPlayerLoadComplete() {
        console.log('Starting content capture process');
        const start = Date.now();
        const jobType = this.manifest.jobType;
        if (jobType === 'RENDER_FRAMES') {
            //
            // Render the frames by getting the pixels from the canvas
            //

            this.canvas = this.storyAnimator.getCanvas();

            // Setup the frame numbers that should be rendered.
            this.numTotalFrames = this.storyAnimator.duration() * this.manifest.fps / 1000;

            const section = this._getFrameSectionForPart(this.manifest.part, this.manifest.numParts, this.numTotalFrames);
            this.frame = section.startingFrame;
            this.numFramesToRender = section.numFramesToRender;

            console.log("StoryCapturer: this.numFramesToRender", this.numFramesToRender)

            // need to seek the player once if the first frame that we need
            // for our job is not the first frame in the animation.
            this.playerNeedsSeek = this.frame > 0;

            if (this.numFramesToRender <= 0) {
                // Nothing to render. Bail.
                return;
            }

            // Render the frames
            const captureCanvasWrapper = () => {
                try {
                    this._captureCanvas();
                } catch (e) {
                    console.error('failed to capture canvas', e);
                    console.error(String(e));
                    if (this.websocket && !this.manifest.disableJettyServer) {
                        console.log('sending remaining buffered messages');
                        this._sendBufferedMessages(this.websocket);
                        this.websocket.close();
                    }
                }
            };
            this.storyAnimator.on('progressUpdate', captureCanvasWrapper);
            captureCanvasWrapper();
        } else if ("RENDER_AUDIO") {
            if (!this.options.isBackendRender) {
                // 
                // TO DO: Remove this once chromium is completely deprecated from service 
                // https://issues.labcollab.net/browse/CURATE-1294
                // Render the audio by using an offline context
                //
                this._captureAudio()
                    .then((xhrStatus) => {
                        console.log('captured audio ', xhrStatus, ' ms time ', Date.now() - start);
                    })
                    .catch((e) => {
                        console.error('failed to capture audio', e);
                        console.error(String(e));
                    });
            } else {
                //
                // Send back story duration used for FFMPeg audio processing
                //
                const storyDuration = this.storyAnimator.duration();
                const msg = {
                    captureCommand: 'STORY_DURATION',
                    storyDuration,
                };
                this.bufferedMessages.push(msg);
                this._sendBufferedMessages(this.websocket);
            }
        } else {
            //
            // Unknown job type
            //
            console.log('Unknown job type!');
        }
    }

    _onLoadError() {
        console.error('Error loading Story Animator');
    }

    /**
     * Returns the startingFrame and the numFramesToRender given the
     * current part, total number of parts, and the total number of
     * frames in a story.
     */
    _getFrameSectionForPart(part, numParts, numTotalFrames) {

        // framesPerPart will always be equal to or less than the numTotalFrames/ numParts.
        // This means that it might cause the final slice to be bigger than the other slices.
        let framesPerPart = Math.floor(numTotalFrames / numParts);

        // setting the starting frame
        let startingFrame = part * framesPerPart;

        var numFramesToRender = null;

        // set the total number of frames to capture
        if (part === numParts - 1) {
            // Last part: gets the remainder of the frames
            numFramesToRender = numTotalFrames - startingFrame;
        } else {
            // Everything except the last part gets the framesPerPart
            numFramesToRender = framesPerPart;
        }

        return {
            startingFrame: startingFrame,
            numFramesToRender: numFramesToRender
        };
    }

    // Hijack all console logs and send them over the websocket.
    _hijackConsoleLogs() {
        if (!console) {
            return;
        }

        // Omit debug logs for now as they're too chatty.
        const levels = ['log', 'warn', 'error'];
        const originalConsole = {
            ...console,
        };

        for (let level of levels) {
            console[level] = (...args) => {
                this._log(level, ...args);
                originalConsole[level](...args);
            }
        }
    }

    // Enqueues a new capture command which contains the message being logged.
    _log(level, ...args) {
        if (!args) {
            return;
        }

        const description = level + ': ' + args.join(' ');
        const completedMessage = {
            captureCommand: 'CONSOLE_MESSAGE',
            description,
        };

        this.bufferedMessages.push(completedMessage);
    }
}

//exposing it out so that NodeJs module can use it. Could not find a better way as webpack module is wrapping things inside on production builds.
window.StoryCapturer = StoryCapturer;
