/**
 *  Copied logic from CloudDriveWebCoreAssets
 *  Parses the string after the ? in a url into a map, expanding comma-delimited values into arrays.
 *  Decodes parameters for convenience.
 * 
 *  E.g.
 *  var parseQueryString = require(parse-query-string.js),
 *      queryMap = parseQueryString(window.location.search);
 */
export default function(queryString) {
    const params = (queryString || '').split(/\?|&/),
        paramMap = {};

    params.forEach((param) => {
        try {
            let pair = param.split(/=/),
                key,
                value;

            if (pair.length === 2) {
                key = pair[0];

                // Attempt to understand an array
                value = pair[1].split(/,/);
                if (value.length > 1) {
                    paramMap[key] = value.map(decodeURIComponent);
                }

                // Otherwise assign as single value
                else if (value.length === 1) {
                    paramMap[key] = decodeURIComponent(value[0]);
                }
            }
            // TODO: maybe use undefined for parameters without "="
        }
        catch (e) {
            // pls no oob
        }
    });

    return paramMap;
}